.todo__container {
  /* text-align: center; */
  display: float;
  align-items: center;
}
.todo__input {
  display: block;
  padding: 1rem 0.5rem;
  border: 0;
  outline: none;
  color: red;
  font-family: "roboto-regular", sans-serif;
  font-size: 1.1rem;
  width: 90%;
  background-color: rgba(0, 0, 0, 0);
  border-bottom: 2px solid black;
}
.button {
  font-size: 1rem;
  text-transform: uppercase;
  letter-spacing: 0.09rem;
  line-height: 3.8rem;
  padding: 0 2rem;
  margin: 0 0.4rem 1.2rem 0;
  background: #292525;
  color: #ffffff;
  cursor: pointer;
  border: none;
}
.add__todo__header {
  text-align: center;
  font-size: 2rem;

  margin-bottom: 0.5em;
  /* width: 95%; */
}
.all__todo {
  width: 100%;
  // margin: 20px auto;
}
.todo__title {
  font-size: 5rem;
  margin: 0;
}

.create__todo,
.modify__todo {
  width: 40%;
  margin: 30px auto;
  /* border: 2px solid black; */
  padding: 20px 20px;
  /* box-shadow: 5px 5px 5px rgba(68, 68, 68, 0.6); */
  box-shadow: 0px 20px 30px rgba(0, 0, 0, 0.1), 0px 8px 8px rgba(0, 0, 0, 0.15),
    0px 4px 4px rgba(0, 0, 0, 0.5);
}
.modify__todo,
.create__todo:hover {
  box-shadow: 0px 20px 30px rgba(0, 0, 0, 0.2), 0px 8px 8px rgba(0, 0, 0, 0.25),
    0px 4px 4px rgba(0, 0, 0, 0.6);
  transform: scale(1.005);
}
.no__todo {
  margin-top: 20px;
  text-align: center;
}

.button__wrapper {
  text-align: center;
}
.button__display {
  text-align: right;
  margin-top: 20px;
}

.label__name {
  font-size: 1.2rem;
}
@media screen and (min-width: 500px) {
  .all__todo {
    // width: 70%;
  }
}
@media screen and (max-width: 1000px) {
  .todo__container {
    width: 40%;
  }
  .create__todo,
  .modify__todo {
    width: 100%;
    margin: 20px auto;
    border: none;
    box-shadow: initial;

    &:hover {
      box-shadow: initial;
      transform: initial;
    }
  }
  .todo__input {
    display: block;
    padding: 0.9rem 0.1rem;
  }
}
@media(max-width:500px){
  .all_tododiv{
    display: block !important;
  }
}
@media screen and (max-width: 800px) {
  .todo__container {
    width: 85%;
  }
}
.inner {
  border-top: 1px solid #2e86ab;
}
.expand {
  display: flex;
  justify-content: flex-end;
  background-color: #fff;
}
.card {
  background: #fff;
  border-radius: 2px;
  // padding: 20px 20px;
  //   padding-bottom: 2em !important;
  //   display: inline-block;
  padding-top: 10px;
  padding-bottom: 10px;
  // margin: 1rem 0rem;
  position: relative;
  box-shadow: 0 0px 2px rgba(#000, 0.2);
  cursor: pointer;
  transition: 0.3s ease;
  //   box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);

    box-shadow: 0 2px 4px rgba(#000, 0.2), 0 4px 8px rgba(#000, 0.2);
 
}
.text{
  color: #333;
}
.progress{
  background-color: rgb(245, 236, 65);
  align-self: center;
  padding: 4px !important;
}
.completed{
  background-color: rgb(65, 245,95);
  align-self: center;
  padding: 4px !important;
}