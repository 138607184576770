.header___top {
  /* text-align: center; */
  background-color: #332E3C;
  color: #fff;
  padding: 10px 0;
  border-radius:11px;
}

.container___info{
  border-radius:11px;
  padding: 20px 0;
  margin-top: 1rem;
  background-color: #e6e9f0;
}

.generatePDF{
  margin: 10px !important;
  background-color: #345BAE;
  border: none;
  border-radius:11px;
  padding: 15px 10px;
  color: #fff;
  font-size: 15px;
  text-align: center;
}
.ListItem{
  cursor: pointer;
}
.table___detail{
  display: flex;
  flex-direction: column;
  background-color: #fff;
}

.table___box{
  border-radius:11px;
  padding: 10px;
  margin-top: 1rem;
}
.single___box{
  margin-top: 20px;
  background-color: #e6e9f0;
  padding: 20px;
}
.single___container{
  display: flex;
  justify-content: space-between;

}

// @media screen and (max-width: 900px) {
//   .single___container{
//     overflow-x: scroll;
//   }
// }