@media(max-width:560px){
    body > div.MuiDialog-root > div.MuiDialog-container.MuiDialog-scrollPaper > div > div.MuiDialogContent-root{
        flex: .25 1 auto !important;
    }
    .buttonGrid{
        width: -webkit-fill-available;
    }
    #root > div:nth-child(3) > div.MuiGrid-root.MuiGrid-container.MuiGrid-align-items-xs-center.MuiGrid-justify-xs-space-around > div:nth-child(1) > button > span.MuiButton-label > input[type=file]{
        width: 190px;
    }
}

body > div.MuiDialog-root > div.MuiDialog-container.MuiDialog-scrollPaper > div > div.MuiDialogActions-root.MuiDialogActions-spacing >
 button.MuiButtonBase-root.MuiButton-root.MuiButton-contained.MuiButton-containedSecondary
 {
    background-color:#d11d18;
 }

body > div.swal2-container.swal2-center.swal2-backdrop-show
 {
    z-index: 1400;
 }
 body > div.MuiDialog-root.mt-5 > div.MuiDialog-container.MuiDialog-scrollPaper > div > div.MuiDialogContent-root > 
 form > div:nth-child(8) > div:nth-child(2) {
    max-width: 100%;
    flex-basis: 100%;
 }
 @media(max-width:600px){

     #root > div.MuiGrid-root.MuiGrid-container > div:nth-child(3) > div{
        width: fit-content;
     }
 }
 .MuiDivider-root {
    border: none;
    height: 1px;
    margin: 0;
    flex-shrink: 0;
    background-color: rgba(0, 0, 0, 1) !important;
}