#lock-menu > div.MuiPaper-root.MuiMenu-paper.MuiPopover-paper.MuiPaper-elevation8.MuiPaper-rounded{
    top: 22% !important;
    min-width: 18%;
    left: 5px !important;
}
.first-letter{
    height: 40px;
    align-items: center;
    display: flex;
    justify-content: center;
    width: 40px;
    background: blue;
    color: #fff;
    border-radius: 100%;
    margin-right: 5px;
    padding: 5px;
    text-align: center;
    font-size: 12px;
}
body > div.MuiDrawer-root.MuiDrawer-modal.makeStyles-drawer-13 > div.MuiPaper-root.MuiDrawer-paper.makeStyles-drawerPaper-14.MuiDrawer-paperAnchorLeft.MuiPaper-elevation16 > ul > div:nth-child(1) > nav > li{
    padding-right: 0;
}
.activeitem{
    background-color: rgb(0 0 0 / 8%);
}