.header___top {
  /* text-align: center; */
  background-color: #332E3C;
  color: #fff;
  padding: 10px 0;
}

.container___info{
  margin-top: 1rem;
}

.generatePDF{
  margin: 10px !important;
  background-color: #345BAE;
  border: none;
  border-radius:11px;
  padding: 15px 10px;
  color: #fff;
  font-size: 15px;
  text-align: center;
}
.ListItem{
  cursor: pointer;
  margin-top: 30px;
  padding: 20px 0;
  border-radius:11px;
  background-color: #e6e9f0;
}
.table___detail{
  
  background-color: #fff;
}

.table___box{
  border-radius:11px;
  padding: 10px;
  margin-top: 1rem;
  background-color: #e6e9f0;
}