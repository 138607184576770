.checklist__container {
  /* text-align: center; */
  display: flex;
  align-items: center;
}
.checklist__input {
  display: block;
  padding: 1rem 0.5rem;
  border: 0;
  outline: none;
  color: black;
  font-family: "roboto-regular", sans-serif;
  font-size: 1.1rem;
  width: 90%;
  background-color: #ffffff;
  border-bottom: 2px solid black;
}
.button {
  font-size: 1rem;
  text-transform: uppercase;
  letter-spacing: 0.09rem;
  line-height: 3.8rem;
  padding: 0 2rem;
  margin: 0 0.4rem 1.2rem 0;
  background: #9e9898;
  color: #ffffff;
  cursor: pointer;
  border: none;
  align-self: flex-end;
}
.add__checklist__header {
  text-align: center;
  font-size: 2rem;
  margin-bottom: 0.5em;
  /* width: 95%; */
}
.all__checklist {
  width: 70%;
  margin: 0px auto;
}
.checklist__title {
  font-size: 2rem;
  margin: 0;
}
.create__checklist {
  background-color: #ffffff;
  width: 60%;
  margin: 30px auto;
  /* border: 2px solid black; */
  padding: 20px 20px;
  /* box-shadow: 5px 5px 5px rgba(68, 68, 68, 0.6); */
  box-shadow: 0px 20px 30px rgba(0, 0, 0, 0.1), 0px 8px 8px rgba(0, 0, 0, 0),
    0px 4px 4px rgba(0, 0, 0, 0);
}
/*.create__checklist:hover {
  box-shadow: 0px 20px 30px rgba(0, 0, 0, 0.2), 0px 8px 8px rgba(0, 0, 0, 0.25),
    0px 4px 4px rgba(0, 0, 0, 0.6);
  transform: scale(1.005);
}*/
.no__checklist {
  margin-top: 20px;
  // text-align: center;
}

.button__wrapper {
  text-align: center;
}
.button__display {
  text-align: right;
  margin-top: 20px;
  margin-right: 20px;
}

.label__name {
  font-size: 1.2rem;
}
@media screen and (max-width: 1000px) {
  .checklist__container {
    width: 40%;
  }
  .create__checklist,
  .modify__checklist {
    width: 100%;
    margin: 20px auto;
    border: none;
    box-shadow: initial;

    &:hover {
      box-shadow: initial;
      transform: initial;
    }
  }
  .checklist__input {
    display: block;
    padding: 0.9rem 0.1rem;
  }
}
@media screen and (max-width: 800px) {
  .checklist__container {
    width: 85%;
  }
}

@media screen and (max-width: 500px) {
  .all__checklist {
    width: 95%;
  }
}
.material__container {
  /* text-align: center; */
  display: flex;
  align-items: center;
}
.material__input {
  display: block;
  padding: 1rem 0.5rem;
  border: 0;
  outline: none;
  color: black;
  font-family: "roboto-regular", sans-serif;
  font-size: 1.1rem;
  width: 90%;
  background-color: rgba(0, 0, 0, 0);
  border-bottom: 2px solid black;
}
.button {
  font-size: 1rem;
  text-transform: uppercase;
  letter-spacing: 0.09rem;
  line-height: 3.8rem;
  padding: 0 2rem;
  margin: 0 0.4rem 1.2rem 0;
  background: #292525;
  color: #ffffff;
  cursor: pointer;
  border: none;
}
.add__material__header {
  text-align: center;
  font-size: 2rem;
  margin-bottom: 0.5em;
  /* width: 95%; */
}
.all__material {
  width: 70%;
  margin: 0px auto;
}
.material__title {
  font-size: 2rem;
  margin: 0;
}
.create__material,
.modify__material {
  width: 40%;
  margin: 30px auto;
  /* border: 2px solid black; */
  padding: 20px 20px;
  background-color: #ffffff;
  /* box-shadow: 5px 5px 5px rgba(68, 68, 68, 0.6); */
  box-shadow: 0px 20px 30px rgba(0, 0, 0, 0.1), 0px 8px 8px rgba(0, 0, 0, 0),
    0px 4px 4px rgba(0, 0, 0, 0);
}
.no__material {
  margin-top: 20px;
  text-align: center;
}

.button__wrapper {
  text-align: center;
}
.button__display {
  text-align: right;
  margin-top: 20px;
  margin-right: 20px;
}

.label__name {
  font-size: 1.2rem;
}
@media screen and (max-width: 1000px) {
  .material__container {
    width: 40%;
  }
  .create__material,
  .modify__material {
    width: 100%;
    margin: 20px auto;
    border: none;
    box-shadow: initial;

    &:hover {
      box-shadow: initial;
      transform: initial;
    }
  }
  .material__input {
    display: block;
    padding: 0.9rem 0.1rem;
  }
}
@media screen and (max-width: 800px) {
  .material__container {
    width: 85%;
  }
}

@media screen and (max-width: 500px) {
  .all__material {
    width: 95%;
  }
}
