@media(max-width:560px){
    .contractor-filter{
        justify-content: space-evenly !important;
    }
    .contractor-filter .MuiGrid-item{
        margin: 0px !important;
        width: auto !important;
    }
}
#panel1d-content > div > div:nth-child(1) > div:nth-child(2) > div.MuiGrid-root.MuiGrid-item{
    position: absolute;
    top: 0;
    right: 10px;
    top: 18px;
    width: auto;
}
#panel1d-content{
    padding: 0px 15px;
}