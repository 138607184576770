.completeSt, .pendingSt, .in-progressSt{
    margin-left: 1rem;
}
@media (max-width:560px){
    .solved{
        padding-right: 10px;
        margin-right: 10px;
    }
    .padding-top{
        padding-top: 15px;
    }
    .solved h6{
        font-size: 1rem;
    }
    .pendingIss h6{
        font-size: 1rem;
    }
    .status{
        justify-content: space-between;
    }
    .completeSt, .pendingSt, .in-progressSt{
        margin-left: 0rem;
    }
}
.Completed{
    color: #1BD741;
}
.Pending{
    color: #FFC107;
}
.InProgress{
    color: rgb(31, 137, 236);
}