.SAH .MuiTableCell-head{
    color: #fff !important;
}
.MuiTableCell-root{
    border-bottom: 0px !important;
}
.MuiTableRow-head{
    background: #345BAE !important;
}
.MuiTableBody-root .MuiTableRow-root{
    background: rgba(52, 91, 174, 0.1)
}
.MuiGrid-spacing-xs-2{
    margin: 0 !important;
}
.MuiAccordionSummary-content.Mui-expanded{
    margin: 0px !important;
}
#root > div:nth-child(2) > div.container > div.container > div.style_table___box__22-pQ > div:nth-child(2) > div > div.MuiCollapse-container.MuiCollapse-entered > div > div > div > div > div > table > tbody > tr:last-child > th:last-child,
#root > div:nth-child(2) > div.container > div.container > div.style_table___box__22-pQ > div:nth-child(2) > div > div.MuiCollapse-container.MuiCollapse-entered > div > div > div > div > div > table > tbody > tr:last-child > td.MuiTableCell-sizeSmall:last-child{
    border-bottom-right-radius: 10px;
}
#root > div:nth-child(2) > div.container > div.container > div.style_table___box__22-pQ > div:nth-child(2) > div > div.MuiCollapse-container.MuiCollapse-entered > div > div > div > div > div > table > tbody > tr:last-child > td:first-child,
#root > div:nth-child(2) > div.container > div.container > div.style_table___box__22-pQ > div:nth-child(2) > div > div.MuiCollapse-container.MuiCollapse-entered > div > div > div > div > div > table > tbody > tr:last-child > td.MuiTableCell-sizeSmall:first-child{
    border-bottom-left-radius: 10px;
}
@media(max-width:768px){
    #root > div:nth-child(2) > div.container > div > div:nth-child(1) > button{
        margin: 10px 0px !important;
    }
    #root > div:nth-child(2) > div.container > div > div:nth-child(2) > button,
    #root > div:nth-child(2) > div.container > div > div:nth-child(3) > button{
        width: 100%;
        margin: 10px 0px !important;
    }
}