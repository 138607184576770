.MuiGrid-container {
    width: 100% !important;
    display: flex;
    box-sizing: border-box;
}

.MuiGrid-justify-xs-center {
    justify-content: center;
}   
.fit-content{
    width: -webkit-fill-available;
}
@media (max-width: 400px){
    #root > div:nth-child(2) > div.MuiGrid-root.MuiGrid-container.MuiGrid-justify-xs-center > div.MuiGrid-root.MuiGrid-item.MuiGrid-justify-xs-center.MuiGrid-grid-xs-12{
        display: block !important;
        
    }
    .MuiGrid-item{
        width: 100%;
    }

}
@media (max-width: 560px){
    .MuiGrid-item{
        width: 100%;
    }
    #root > div.MuiGrid-root.MuiGrid-container > div:nth-child(4) > div > div > div:nth-child(6) > div > div > div.MuiCardContent-root.makeStyles-issueCard-28 > div.MuiGrid-root.MuiGrid-container.MuiGrid-spacing-xs-2 > div{
        width: fit-content;
    }
    body > div.MuiDialog-root.mt-5 > div.MuiDialog-container.MuiDialog-scrollPaper > div > div > form > div.MuiGrid-root.MuiGrid-container.MuiGrid-justify-xs-center > div.MuiGrid-root.mr-4.MuiGrid-item,
    body > div.MuiDialog-root.mt-5 > div.MuiDialog-container.MuiDialog-scrollPaper > div > div > form > div.MuiGrid-root.MuiGrid-container.MuiGrid-justify-xs-center > div:nth-child(2){
        width: fit-content;
        margin-top: 20px;
    }
}
@media (min-width: 600px){
    .MuiGrid-grid-sm-2 {
        flex-grow: 0;
        max-width: 18.666667% !important;
        flex-basis: 18.666667% !important;
    }
}

body > div.MuiDialog-root > div.MuiDialog-container.MuiDialog-scrollPaper > div 
> div.MuiDialogContent-root > p:nth-child(6)

{
    margin-top: 20px;
}

body > div.MuiDialog-root > div.MuiDialog-container.MuiDialog-scrollPaper > div 
> div.MuiDialogContent-root > p:nth-child(4)
{
    margin-top: 10px;
}

@media (max-width: 560px){
body > div.MuiDialog-root > div.MuiDialog-container.MuiDialog-scrollPaper > div >
 div.MuiDialogActions-root.MuiDialogActions-spacing
{
    margin-right: 20px;
    margin-bottom: 20px;
    flex: .99 1 auto !important;
}
}

#root > div:nth-child(3) > div.container > div > h4
{
    font-size: 1rem;
}

/* body > div.MuiDialog-root.mt-5 > div.MuiDialog-container.MuiDialog-scrollPaper > div > 
div.MuiDialogActions-root.MuiDialogActions-spacing
{
    margin-bottom: 18em !important;
   
} */
#root > div.makeStyles-root-1 > header > div > h6
{
    margin-right: 1em;
}


#root > div:nth-child(2) > div:nth-child(2) > form > div > div.MuiGrid-root.MuiGrid-container.MuiGrid-spacing-xs-2 >
 div.MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-12.MuiGrid-grid-sm-2{
    
 }
 .MuiSlider-root,.MuiSlider-rail,.MuiSlider-track,.MuiSlider-mark{
    height: 10px !important;
 }
 .MuiSlider-thumb{
    height: 20px !important;
    width: 20px !important;
 }
 .MuiSlider-valueLabel {
    left: calc(-50% - -4px) !important;
}
.MuiSlider-root {
    margin: 40px 0 !important;
    padding: 0 !important;
}
@media(max-width: 768px){
    .Abreadcrumb h6{
        padding: 10px 9px !important;
        word-break: break-all;
    }
}